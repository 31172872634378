import axios from "axios";
import { apiEndPoint } from "../../statics/config";
import localizationStrings from "../../statics/localization";

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    config.url = "https://atlas.saatteknoloji.com.tr/api" + config.url;

    config.headers.Authorization = localStorage.getItem("profileToken");

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

const axiosInstance = axios.create({
  baseURL: "https://atlas.saatteknoloji.com.tr/api"
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.url.includes("/login") && !config.url.includes("/language"))
      config.headers.Authorization = localStorage.getItem("userToken");
    else if(config.url.includes("/language")){
      config.headers.Authorization = localStorage.getItem("profileToken");
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const getFrameConfigs = async (userUrl, sessionId) => {
  return await axios.get(`${userUrl}/sdk_getuserframeconfig.jsp`);
};

const setCookieApi = async (key, value) => {
  let apiEndpoint = localStorage.getItem("interfaceurl");
  let sessiondId = localStorage.getItem("sessionid");
  return await axiosInstance.post(
    `${apiEndPoint}/sdk_setcookie.jsp`,
    `key=${key}&value=${value}`
  );
};

const getCookieApi = async () => {
  let apiEndpoint = localStorage.getItem("interfaceurl");
  let sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getcookie.jsp`);
};

const setCookieApiCall = async (key, value, isRefresh = true) => {
  const { data } = await setCookieApi(key, value);
  if (isRefresh) {
    localizationStrings.setLanguage(value);
    window.location.reload();
  }
};

const getColumnList = async (columnCode, orderType) => {
  let apiEndpoint = localStorage.getItem("interfaceurl");
  return await axiosInstance.get(`${apiEndPoint}/sdk_getcolumnlist.jsp`, {
    params: {
      columncode: columnCode,
      ordertype: orderType
    }
  });
};

const getWebSocketInfo = async (userCode, mesReportUrl, deviceid) => {
  return await axios.get(`${mesReportUrl}/terminalimporthttpsreq`, {
    params: {
      TransactionID: new Date().getTime(),
      UserID: userCode,
      TerminalFlag: 4,
      DeviceID: deviceid,
      Timestamp: new Date().getTime()
    }
  });
};

const getEpgDate = async () => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getepgdate.jsp`);
};

const getUserLanguagePreferences = async () => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  let obj = `{"lng":"||"}`;
  return await axios.get(`${apiEndpoint}/sdk_getuserdata.jsp`, {
    params: {
      datatype: 20
      // datavalue: encodeURIComponent(obj)
    }
  });
};

const setUserLanguagePreferences = async (obj) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  // let obj = `{"lng":"||"}`;
  return await axiosInstance.get(`${apiEndPoint}/sdk_setuserdata.jsp`, {
    params: {
      datatype: 20,
      datavalue: obj
    }
  });
};

const getUserLastChannelData = async () => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios
    .get(`${apiEndpoint}/sdk_getuserdata.jsp`, {
      params: {
        datatype: 3
      },
      timeout: 1000
    })
    .catch((err) => {
      return err;
    });
};

const getUserOnlineQueryApi = async (url, usercode) => {
  var sessiondId = localStorage.getItem("sessionid");
  // let obj = `{"lng":"||"}`;
  return await axios.post(
    `${url}/useronlinequery`,
    `{ "usercode": "${usercode}" }`
  );
};

const getEncryptData = async (isNeedTime) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  return await axios.get(`${apiEndpoint}/sdk_getencryptdata.jsp`, {
    params: {
      isneedtimestamp: isNeedTime
    }
  });
};

export {
  axiosInstance,
  getFrameConfigs,
  getColumnList,
  getWebSocketInfo,
  getEpgDate,
  getUserLanguagePreferences,
  setUserLanguagePreferences,
  setCookieApi,
  setCookieApiCall,
  getCookieApi,
  getUserOnlineQueryApi,
  getEncryptData,
  getUserLastChannelData
};
