import React, { useEffect, useState } from "react";
import { isFirefox } from "react-device-detect";
import { createPortal } from "react-dom";
import { checkProfilePassword } from "../../api/user/user";
import localizationStrings from "../../statics/localization";
import firefoxPinSecret from "../../utils/firefoxPinSecret";
import Notification from "../notification";

const PasswordModal = ({ text, sendResult, profile }) => {
  const [viewClass, setViewClass] = useState("");
  const [profilePassword, setProfilePassword] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState("");
  const [i, setI] = useState(0);
  const [pinText, setPinText] = useState("");

  const confirmClicked = () => {
    if (!!profilePassword.trim()) {
      checkPassword(profilePassword);
    }
  };

  const notConfirmClicked = () => {
    setViewClass("");

    setTimeout(() => {
      sendResult(false);
    }, 300);
  };

  const checkPassword = async (password) => {
    let encodedPassword = window.EncryptUtils.encryptStr(password);
    const data = await checkProfilePassword(
      profile.profilecode,
      encodedPassword
    );
    if (data.data.returncode === "0") {
      sendResult(true);
    } else if (data.data.returncode === "1") {
      setShowNotification(true);
      setNotificationText(localizationStrings.wrongPinText);
    } else {
      setShowNotification(true);
      setNotificationText(data.data.errormsg);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setViewClass("open");
    }, 20);
  }, []);

  return createPortal(
    <div className={`notification-container ${viewClass}`}>
      <div className="box">
        <p className="text">{text}</p>
        <form id="passwordModalForm">
          {!isFirefox ? (
            <input
              className="profile-input pin"
              type="number"
              name="profilePin"
              autoComplete="off"
              id="passwordModalPin"
              value={profilePassword}
              onKeyDown={(e) =>
                ["e", "E", "+", "-", ".", ","].includes(e.key) &&
                e.preventDefault()
              }
              onChange={(e) => {
                if (e.target.value.length > 4) {
                  e.target.value = e.target.value.slice(0, 4);
                }
                setProfilePassword(e.target.value);
              }}
              placeholder={localizationStrings.login.password}
            />
          ) : (
            <input
              className="profile-input"
              type="text"
              name="profilePin"
              autoComplete="off"
              id="profileListPin"
              //value={profilePassword.replace(/[0-9]/g, "•")}
              onMouseUp={(e) => {
                let [pin, i] = firefoxPinSecret(null, e);
                setI(i);
                setPinText(pin.replace(/[0-9,•]/g, "•"));
                setProfilePassword(pin);
              }}
              onKeyDown={(e) => {
                let [pin, i] = firefoxPinSecret(e, null);
                setI(i);
                setPinText(pin.replace(/[0-9,•]/g, "•"));
                setProfilePassword(pin);
              }}
              onChange={(e) => {
                e.target.value = pinText;
                e.target.selectionEnd = i;
                e.target.selectionStart = i;
              }}
              placeholder={localizationStrings.login.password}
            />
          )}
        </form>
        <div className="button-row" style={{ marginTop: "20px" }}>
          <button
            className="btn btn-blue"
            style={{ marginRight: "20px", minWidth: "150px" }}
            onClick={() => confirmClicked()}
          >
            {localizationStrings.login.confirm}
          </button>
          <button
            className="btn btn-gray"
            style={{ minWidth: "150px" }}
            onClick={() => notConfirmClicked()}
          >
            {localizationStrings.cancel}
          </button>
        </div>
      </div>
      {showNotification ? (
        <Notification
          text={notificationText}
          confirmText={localizationStrings.close}
          onClickConfirm={() => setShowNotification(false)}
        />
      ) : null}
    </div>,
    document.getElementById("notification_root")
  );
};

export default PasswordModal;
